import React, { useState } from 'react'
import {TextField, Button} from '@mui/material';
import { useHistory } from "react-router-dom";
import logo_t from '../asstes/images/logo_t.png'

export default function Login() {
  const [username, setUseName] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory();

  const handleLogin = ()=>{
    if(username !== "" && password !== ""){
      localStorage.setItem("USER_EMAIL", JSON.stringify({username,password}))
      history.push("/");
      document.location.reload()
    }
  }

  return (
    <>
    <div className='login-container'>
    </div>
    <div className='login-form'>
      <img src={logo_t} width={300}/>
    <TextField
          className='data-input'
          id="filled-helperText"
          label="Email"
          variant="filled"
          margin="normal"
          onChange={(e)=>{setUseName(e.target.value)}}
        />
    <TextField
      className='data-input'
      id="filled-helperText"
      label="Password"
      variant="filled"
      margin="normal"
      onChange={(e)=>{setPassword(e.target.value)}}
    />
    <Button className='main-button' variant="contained" margin="normal" onClick={handleLogin}>Log In</Button>
    </div>
  </>
  )
}
