import React, { useEffect, useState } from 'react'
import Pusher from "pusher-js";
import instance from '../api';
import Map from '../components/Map';
import Header from '../components/Header';
import { useSelector } from 'react-redux';
import { params } from '../features/MapData/MapDataSlice'

export default function HomePage() {
  const serverParams = useSelector(params);
    const [realTimeDriver, setRealTimeDriver] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    //===  Getting channels data
    useEffect(() => {
      instance
        .post("/driver/active/", serverParams)
        .then((res) => {
          const { data } = res;
          setIsLoading(false);
    
          Pusher.logToConsole = false;
          const pusher = new Pusher("ac0b156cdc093a70cb15", {
            authEndpoint: "https://api.voltlines.com/v1/api/core/pusher/auth",
            cluster: "eu",
            forceTLS: true,
          });
    
          pusher.logToConsole = false;
    
          pusher.connection.bind('connected', changeConnectionState);
          function changeConnectionState() {
            console.log('connected')
            data.forEach((channel) => {
              pusher
                .subscribe(channel.channel_name)
                .bind("client-driver-location-update", (data) => {
                  const lng = Number(data.coordinates.split(",")[0]);
                  const lat = Number(data.coordinates.split(",")[1]);
    
                  setRealTimeDriver((prevRealTimeDriver) => {
                    const updatedDriver = [...prevRealTimeDriver];
                    const driverIndex = updatedDriver.findIndex(
                      (item) => item.id === channel.driver_id
                    );
    
                    if (driverIndex !== -1) {
                      // Driver already exists, update coordinates
                      updatedDriver[driverIndex].coordinates = [lat, lng];
                    } else {
                      // Driver does not exist, add new driver
                      updatedDriver.push({
                        coordinates: [lat, lng],
                        id: channel.driver_id,
                        trip_state: channel.trip_state
                      });
                    }
    
                    return updatedDriver;
                  });
                });
            });
          }
        })
        .catch((err) => console.log(err));
    }, [serverParams]);
    

    // useEffect(() => {
    //   setInterval(() => {
    //     try {
    //       const { data } = instance.post("/driver/active/", serverParams)

    //       const updatedSecondArray = realTimeDriver.map((old) => {
    //         const matchingObj = data.find((newItem) => newItem.trip_id === old.trip_id);
    //         return matchingObj ? { ...old, trip_state: matchingObj.trip_state } : old;
    //       });

    //       setRealTimeDriver(updatedSecondArray)

    //     } catch(e){
    //       console.error(e)
    //     }
    //   }, 10000);
    // }, [])

  return (
    <div className='home-page'> 
      <Header/>
      <Map DriversData={realTimeDriver}  isLoading={isLoading}/>
    </div>
  )
} 
