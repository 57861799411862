import React, { useState } from 'react';
import { TextField, Button, Slider} from '@mui/material';

const TimeSlider = ({ value, changeSliderValue, formattedValue, changeTimeFromAPIRequest }) => {
  const minutesToTimeString = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  };


  const timeStringToMinutes = (timeString) => {
    const [hours, mins] = timeString.split(':').map(Number);
    return hours * 60 + mins;
  };

  const handleChange = (event, newValue) => {
    changeSliderValue(newValue);
  };

  const handleTimeChange = (event, newValue)=>{
    changeTimeFromAPIRequest(newValue)
  }

  return (
      <div className='time-slider-container'>
        <span>{ formattedValue[0] } - { formattedValue[1] }</span>
      <Slider
        className='time-slider'
        value={value}
        min={0}
        max={24 * 60}
        onChange={handleChange}
        onChangeCommitted={handleTimeChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => minutesToTimeString(value)}
        aria-labelledby="time-slider"
        disableSwap
        getAriaLabel={() => 'Minimum distance shift'}
      />
      <div><span>00:00</span><span>24:00</span></div>
      </div>
  );
};

export default TimeSlider;
