import React from 'react'
import DeckGL, { IconLayer } from "deck.gl";
import StaticMap from "react-map-gl";
import { MapView } from "@deck.gl/core";
import SideBar from './SideBar';

export default function Map({ DriversData, isLoading }) {
  const MAP_VIEW = new MapView({ repeat: true }); 
    const INITIAL_VIEW_STATE = {
    longitude: 28.8860894,
    latitude: 41.06155,
    zoom: 9,
    maxZoom: 20,
    pitch: 0,
    bearing: 0
    };
    //=== light map:
    // const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json';
    //=== dark map:
    const MAP_STYLE =
    "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";


  const layer = new IconLayer({
    id: "icon-layer",
    data: DriversData,
    pickable: true,
    getPosition: (d) => d.coordinates,
    getIcon: (d) => ({
      url: "https://upload.wikimedia.org/wikipedia/commons/1/11/Pan_Green_Circle.png",
      width: 128,
      height: 128,
      anchorY: 128
    }),
    sizeScale: 2,
    sizeMinPixels: 10,
  }); 


  return (
    <div className='map-container'>
    <SideBar DriversData={DriversData} isLoading={isLoading}/>
    <DeckGL
    layers={[layer]}
    views={MAP_VIEW}
    initialViewState={INITIAL_VIEW_STATE}
    controller={true}
  >
    <StaticMap
      mapboxAccessToken="pk.eyJ1IjoibXVuaXJzYWZmdXIiLCJhIjoiY2w0aDFnOXR1MDBoYjNjcDVteDB0amlkYyJ9.Evi9x_FYwpfuRSGsStxnsQ"
      reuseMaps
      mapStyle={MAP_STYLE}

    />
  </DeckGL>
  </div>
  )
}
