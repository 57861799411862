import axios from "axios"
const instance = axios.create({
    baseURL: "https://api.staging.voltlines.com/v1/api/",
    headers: {
        "Content-type": "application/json",
      },
    auth: {
        username: "ahmet.sahin@voltlines.com",
        password: "1234qwer"
        }
});



export default instance