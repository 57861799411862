import React from 'react'

export default function SideBar({DriversData, isLoading}) {
  

  return (
    <div className='side-bar'>
      <div className='side-bar-content'>
        <div className='side-bar-title'>Trip Funnle</div>
        <div className='side-bar-container'>
         <ul >
          <div><span>Live Trip Count: </span> <p>{isLoading ? 'Refreshing...' : DriversData.length } </p></div>
          <li>
            <div className='first_op'><span>Starting Soon</span><p>{DriversData.filter(item=> item.trip_state === "completed").length}</p></div>
          </li>
          <li>
            <div><span>Missed</span><p>50</p></div>
          </li>
          <li>
            <div  className='last_op'><span>Started</span><p>{DriversData.filter(item=> item.trip_state === "scheduled").length}</p></div>
            <ul>
              <li>
                <div className='first_op'><span>Starting Soon</span><p>{DriversData.filter(item=> item.trip_state === "ready-for-ride").length}</p></div>
              </li>
              <li>
                <div><span>Missed</span><p>50</p></div>
              </li>
              <li>
                <div><span>Started</span><p>{DriversData.filter(item=> item.trip_state === "scheduled").length}</p></div>
              </li>
            </ul>
          </li>
         </ul>
        </div>
      </div>
    </div>
  )
}
