import React, { useEffect, useState } from 'react'
import logo_orange from '../asstes/images/logo_orange.jpg'
import { Button, Chip, Stack, Select, FormControl, MenuItem, OutlinedInput} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import moment from 'moment'
import TimeSlider from './TimeSlider';
import { handleMapData, handleMapCenter } from '../features/MapData/MapDataSlice'
import { useDispatch } from 'react-redux';

export default function Header() {
 
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState([0 , 22*60]);
  const [formattedValue, setFormattedValue] = useState(['00:00', '22:00'])
  const [tripRange, setTripRange] = useState("time_range")
  const [city, setCity] = useState(10);

  const changeSliderValue = (newValue) => {
    setSelectedTime(newValue);
  };

  const changeTimeFromAPIRequest = (newValue)=>{
    const [start, end] = newValue
    const start_H_M = `${Math.floor(start / 60).toString().padStart(2, '0')}:${(start % 60).toString().padStart(2, '0')}`
    const end_H_M = `${Math.floor(end / 60).toString().padStart(2, '0')}:${(end % 60).toString().padStart(2, '0')}`
    const today = moment().format("YYYY-MM-DD")
    setFormattedValue([start_H_M, end_H_M])
    dispatch(handleMapData({
      from_datetime:`${today} ${start_H_M}`,
      to_datetime: `${today} ${end_H_M}`
    }))
    handleTripRange('time_range')
  }

    const handleLogOut = ()=>{
      localStorage.removeItem("USER_EMAIL")
      document.location.reload()
    }

  const theme = createTheme({
    palette: {
      ochre: {
        main: '#E3D026',
        light: '#E9DB5D',
        dark: '#A29415',
        contrastText: '#242105',
      },
    },
  });

  const handleTripRange = (param)=>{
    if(param === 'time_range'){
      setTripRange("time_range")
    } else {
      setTripRange("now")
      dispatch(handleMapData({
        from_datetime: moment().format('YYYY-MM-DD HH:mm'),
        to_datetime: moment().format('YYYY-MM-DD HH:mm')
      }))
    }
  }

  return (
    <ThemeProvider theme={theme}>
    <div className='header-section'>
      <img width="150" src={logo_orange}/>
      <div>
        <p>{ moment().format("YYYY-MM-DD") } <span>{ moment().format("HH-mm") }</span></p>
        <Button className='logout-button' variant="contained" margin="normal" color="ochre" onClick={handleLogOut} endIcon={<LogoutIcon />}>Log out</Button>
      </div>
    </div>
    <div className='header-options'>

      <div style={{display: "flex", marginLeft: "50px", width: "30%"}}>
        <p>City</p>
        <FormControl sx={{ m: 1, ml: 3, minWidth: 200}} size="small">
          <Select
            className='map-center-selector'
            sx={{ borderColor: "#fff", color: "#fff" }}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={city}
            label="Age"
            onChange={(e)=>{setCity(e.target.value);}}
            input={<OutlinedInput />}
          >
            <MenuItem value={10}>Istanbul-Europe</MenuItem>
            <MenuItem value={20}>Istanbul-Asya</MenuItem>
            <MenuItem value={30}>Ankara</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={{display: "flex", marginRight: "50px"}}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', marginRight: '20px' }}>
          <p>Trip Range</p>
          <Chip label="Now" variant="outlined" className={tripRange === 'now' ? 'selected_trip_range' : ' chip'} onClick={()=>{handleTripRange('now')}}/>
          <Chip label="Time Range" variant="outlined" className={tripRange === 'time_range' ? 'selected_trip_range' : 'chip'} onClick={()=>{handleTripRange('time_range')}}/>
        </Stack>
        <TimeSlider value={selectedTime} formattedValue={formattedValue} changeSliderValue={changeSliderValue} changeTimeFromAPIRequest={changeTimeFromAPIRequest}/>
      </div>
    </div>
    </ThemeProvider>
  )
}
