import React, { useEffect } from 'react';
import './asstes/style/App.css';
import { Switch, Route, useHistory } from "react-router-dom";
import HomePage from './layouts/HomePage';
import Login from './layouts/Login'

function App() {
  const history = useHistory();

  useEffect(()=>{
    const getUser = JSON.parse(localStorage.getItem("USER_EMAIL"))
    const allowedEmailDomain = 'voltlines.com';
    
    if (getUser && getUser.username.split('@')[1] === allowedEmailDomain) {
      history.push("/");
    } else {
      history.push("/login");
    }
  }, [])

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/login" component={Login} />
      </Switch>
    </div>
  );
}

export default App;
