import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serverParams:{
    from_datetime: "2023-11-14 10:00",
    to_datetime: "2023-11-17 10:00" 
    },
  MapData: [],
  mapCenter: []
};


export const mapSlice = createSlice({
  name: 'mapCenter',
  initialState,

  reducers: {
   handleMapData: (state, action)=>{
    state.serverParams = action.payload
   },
   storeMasterData: (state, action)=>{
    state.MapData = action.payload
   },
   handleMapCenter: (state, action)=>{
    state.mapCenter = action.payload
   }
  },
 
});

export const { handleMapData, storeMasterData, handleMapCenter } = mapSlice.actions;
export const map_live_data = (state) => state.mapCenter.MapData;
export const params = (state) => state.mapCenter.serverParams;
export const mapCenter = (state) => state.mapCenter.mapCenter;

export default mapSlice.reducer;